import styled from "@emotion/styled"
import { Flex, FlexProps, useThemeUI } from "theme-ui"
import { motion } from "framer-motion"
import { lighten } from "@theme-ui/color"
import { useRipples } from "common/use-ripples"

interface Props extends FlexProps {
  interactive?: boolean
}

const RectOuter = styled(motion.div)(() => {
  const { theme } = useThemeUI() as any

  // console.log("theme", theme)

  return {
    height: `100%`,
    filter: `drop-shadow(${theme.shadows.default})`
  }
})

const Content = styled.div({
  position: `relative`,
  width: `100%`,
  height: `100%`,
  zIndex: 1
})

// TODO: default animation: initial={{ opacity: 0 }} animate={{ opacity: 1 }}

const Rect = ({ children, interactive = false, sx, ...props }: Props) => {
  const { theme } = useThemeUI()
  const [addRipple, ripples] = useRipples({ color: `ripple`, alpha: 0.75 })

  return (
    <RectOuter>
      <Flex
        bg="primary"
        color="inverted"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          clipPath: `polygon(1em 0%, 100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0% 1em)`,
          transition: `background-color 0.2s ease-out, transform 0.2s ease-out`,
          ...(interactive && {
            transform: `perspective(1px) translateY(0)`, // NOTE: These perspective values seem to prevent subpixel shifting during animations. Not sure that they are optimal though.
            "&:hover": {
              backgroundColor: lighten(theme.colors!.primary!, 0.025),
              transform: `perspective(1px) translateY(-5px)`
            }
          }),
          ...sx
        }}
        {...props}
        onMouseDown={interactive ? addRipple : () => {}}
      >
        <Content>{children}</Content>
        {interactive && ripples}
      </Flex>
    </RectOuter>
  )
}

export { Rect }
