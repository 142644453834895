import { useQuery, UseQueryOptions } from "react-query"
import axios from "axios"
import { api, cache } from "../common/constants"
import { loggedIn } from "common/logged-in"

const usePage = loggedIn((name: string, options?: UseQueryOptions) =>
  useQuery(
    ["page", name],
    async () => {
      const { data } = await axios.get(api.asset(name), {
        withCredentials: true
      })
      return data
    },
    {
      cacheTime: cache.page,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      ...options
    }
  )
)

export { usePage }
