import { Flex, FlexProps } from "theme-ui"
import { Link } from "gatsby"
import { useRipples, RippleContent } from "common/use-ripples"

interface Props extends FlexProps {
  path: string
}

const Lesson = ({ children, path, sx, ...rest }: Props) => {
  const [addRipple, ripples] = useRipples({ color: `ripple`, alpha: 0.75 })

  return (
    <Link to={path}>
      <Flex
        role="button"
        bg="primary"
        py={1}
        px={3}
        sx={{
          fontWeight: 700,
          fontSize: 1,
          display: `inline-flex`,
          justifyContent: `center`,
          color: `inverted`,
          borderRadius: `default`,
          position: `relative`,
          overflow: `hidden`,
          zIndex: 1,
          ...sx
        }}
        onMouseDown={addRipple}
        {...rest}
      >
        <RippleContent>{children}</RippleContent>
        {ripples}
      </Flex>
    </Link>
  )
}

export { Lesson }
