import { useRipples } from "common/use-ripples"
import { Link, GatsbyLinkProps } from "gatsby"
import { Box, NavLink as UiNavLink, NavLinkProps, useThemeUI } from "theme-ui"
import { Match } from "@reach/router"

type Props = NavLinkProps & GatsbyLinkProps<true>

const NavLink = ({ children, sx, to, ...props }: Props) => {
  const {
    theme: { colors }
  } = useThemeUI() as any
  const [addRipple, ripples] = useRipples()
  return (
    <Link to={to} onMouseDown={addRipple}>
      <Match path={to}>
        {({ match }) => (
          <UiNavLink
            as="div"
            px={3}
            py={2}
            mx={1}
            sx={{ position: "relative", ...sx }}
            {...props}
          >
            <Box
              as="span"
              py={1}
              sx={{
                transition: "border 200ms ease-out",
                borderBottom: match
                  ? `2px solid ${colors.heading}`
                  : `2px solid transparent`
              }}
            >
              {children}
            </Box>
            {ripples}
          </UiNavLink>
        )}
      </Match>
    </Link>
  )
}

export { NavLink }
