import { createContext } from "react"
import { Translation } from "./types"

/**
 * TODO:
 * - support for changing the language
 * - consider adding the language code as a property
 */

interface Props {
  translations: Translation
}

const Context = createContext<Translation | null>(null)

const TranslationProvider = ({
  translations,
  children
}: WithChildren<Props>) => (
  <Context.Provider value={translations}>{children}</Context.Provider>
)

export { TranslationProvider, Context }
