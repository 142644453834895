import { usePrivateVideo } from "../private-video"
import { Flex, Text } from "theme-ui"
import { Button } from "components"
import { useTranslation } from "translations"
import { format, addSeconds } from "date-fns"

const getSeconds = (s: number) => format(addSeconds(new Date(0), s), "mm:ss") // TODO: create time helpers

const Controls = () => {
  const { playing, play, pause, position, duration } = usePrivateVideo()
  const t = useTranslation("components.PrivateVideo")
  return (
    <Flex my={3}>
      <Button
        p={2}
        sx={{
          fontSize: 2,
          marginRight: 5
        }}
        onClick={playing ? pause : play}
      >
        {playing ? t(`pause`) : t(`play`)}
      </Button>
      <Text>
        {getSeconds(position)} / {getSeconds(duration)}
      </Text>
    </Flex>
  )
}

export { Controls }
