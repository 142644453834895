import { useQuery } from "react-query"
import axios from "axios"
import { api } from "common/constants"

const useLoggedIn = () => {
  const query = useQuery("loggedIn", async () => {
    const { data } = await axios.post(api.authorize, null, {
      withCredentials: true
    })
    return data
  })

  return query
}

export { useLoggedIn }
