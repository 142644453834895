import styled from "@emotion/styled"

const BodyWrapper = styled.div({
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  width: `100vw`
})

export { BodyWrapper }
