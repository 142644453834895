import { Flex } from "theme-ui"
import { Tag } from "components"
import { useCard } from ".."

const Meta = () => {
  const { tags } = useCard()

  if (!tags) {
    return null
  }

  return (
    <Flex
      as="ul"
      p={0}
      mx={4}
      sx={{
        marginTop: 3,
        fontSize: 0,
        textTransform: `uppercase`
      }}
    >
      {tags.map((tag, index) => (
        <Tag key={index}>{tag}</Tag>
      ))}
    </Flex>
  )
}

export { Meta }
