import { toast } from "components/toast"
import { useContext, useState, useEffect } from "react"
import { Context } from "translations"

/**
 * TODO:
 * - add array support for multiple paths
 * - consider the need to return also a component
 * - add support for placeholders
 * - consider need to include other translations directly
 * - consider adding some kind of formatting function
 */

/**
 * Returns a localized value.
 * @param path Path to translation key, for example "views.home.title". Keys must not include dots.
 */
const lookup = (path: string) => (obj: any) =>
  path.split(".").reduce((acc, curr) => acc?.[curr], obj)

/**
 * Returns a lookup function to get localized values.
 * @param path Base path that will be prepended to all lookups, for example "views.home"
 */

type Options = {
  errors?: boolean
}

export const interpolateTranslation = (template: string) => (
  data: Record<string, string>
) =>
  Object.entries(data).reduce(
    (acc, [key, value]) => acc.replace(`\{${key}}`, value),
    template
  )

const useTranslation = (path?: string, options = {} as Options) => {
  const { errors = true } = options
  const translations = useContext(Context)
  const [missingKey, setMissingKey] = useState<string>()

  useEffect(() => {
    if (!errors) {
      return
    }
    missingKey &&
      toast(`Translation missing for key: ${missingKey}`, {
        id: `missing-key-${missingKey}`
      })
  }, [missingKey])

  if (missingKey) {
    return () => (errors ? missingKey : "")
  }

  return (key: string, data?: Record<string, string>) => {
    const fullPath = [path, key].filter(Boolean).join(".")
    const term = lookup(fullPath)(translations)
    if (!term) {
      setMissingKey(fullPath)
    }

    const interpolatedTerm = data ? interpolateTranslation(term)(data) : term

    return interpolatedTerm ?? ""
  }
}

export { useTranslation }
