import { Input, InputProps, Label, useThemeUI, Text } from "theme-ui"
import { ForwardedRef, forwardRef } from "react"
import styled from "@emotion/styled"
import { darken } from "@theme-ui/color"

interface Props extends InputProps {
  label?: string
  error?: any
  name: string
}

const Container = styled.div(({ error }: { error: boolean }) => {
  const { theme } = useThemeUI() as any

  const color1 = darken(theme.colors.error, 0.025)(theme)
  const color2 = darken(theme.colors.error, 0.05)(theme)

  return {
    padding: theme.space[3],
    borderRadius: theme.radii.default,
    overflow: `hidden`,
    position: `relative`,
    marginLeft: `calc(0px - ${theme.space[3]})`,
    ...(error && {
      marginBottom: theme.space[3]
    }),
    "&::before": {
      content: `""`,
      backgroundImage: `repeating-linear-gradient(45deg,${color1},${color1} ${theme.space[3]},${color2} ${theme.space[3]},${color2} ${theme.space[4]})`,
      position: `absolute`,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      opacity: 0,
      zIndex: -1,
      transition: `opacity 0.2s ease-out`,
      ...(error && {
        opacity: 1
      })
    },
    ">[role='assertive']": {
      opacity: 0,
      transition: `opacity 0.2s ease-out`,
      ...(error && {
        opacity: 1
      })
    }
  }
})

const TextField = forwardRef(
  (
    { label, name, error, ...props }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Container error={error}>
        {label && (
          <Label color="primary" sx={{ marginBottom: 2 }}>
            {label}
          </Label>
        )}
        <Input
          ref={ref}
          {...props}
          name={name}
          aria-describedby={`${name}-error`}
          sx={{ variant: "fields.default" }}
        />
        {error && (
          <Text
            role="assertive"
            id={`${name}-error`}
            as="div"
            sx={{
              fontWeight: 700,
              marginTop: 2,
              textShadow: `1px 1px 2px rgba(0, 0, 0, 0.75)`
            }}
          >
            {error.message}
          </Text>
        )}
      </Container>
    )
  }
)

export { TextField }
