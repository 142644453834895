// TODO: Do we really need these?
export const views = {
  blog: `blog`,
  profile: `profile`,
  login: `login`,
  signup: `signUp`,
  home: `home`,
  courses: `courses`
}

export const routes = {
  blog: `/blog`,
  profile: `/profile`,
  login: `/login`,
  signup: `/signup`,
  home: `/home`,
  courses: `/courses`
}

export const api = {
  authorize: `${process.env.apiPath}authorize`,
  asset: (name: string) => `${process.env.apiPath}asset/${name}`,
  login: `${process.env.apiPath}magic-link`,
  signup: `${process.env.apiPath}sign-in`,
  video: (id: string) => `${process.env.apiPath}video/${id}`
}

export const regex = {
  email: /^(?!.*?\.\.)[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/
}

export const cookies = {
  session: `session`
}

export const cache = {
  page: 300000 // 5 minutes
}

export const toasts = {
  network: `network`,
  notLoggedIn: `notLoggedIn`,
  timeout: 5000
}

export const ripples = {
  duration: 300,
  minimumVisibleDuration: 100
}

export const events = {
  addToast: "addToast",
  removeToast: "removeToast"
} as const
