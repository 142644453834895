import { useLoggedIn as loggedInApi } from "api"
import { useErrorToast } from "api/use-error-toast"
import { useContext, createContext } from "react"

const Context = createContext({
  loggedIn: false
})

const LoggedInProvider = ({ children }: WithChildren) => {
  const { isLoading, data, error } = loggedInApi()
  useErrorToast(error)

  // TODO: handle errors and stuff

  return (
    <Context.Provider value={{ loggedIn: !!data ?? false }}>
      <h1>
        LoggedIn:
        {isLoading
          ? `Loading loggedin status`
          : error
          ? `Failed to get loggedin status`
          : JSON.stringify(data)}
      </h1>
      {children}
    </Context.Provider>
  )
}

const useLoggedIn = () => {
  const _context = useContext(Context)
  if (!_context) {
    throw new Error(
      `Logged in status can not be used outside LoggedInProvider.`
    )
  }
  return _context
}

export { LoggedInProvider, useLoggedIn }
