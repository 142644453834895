import { Heading, Text } from "theme-ui"
// import { useTranslation } from "translations"
// import { useForm } from "react-hook-form"
// import { TextField, Button, Stack } from "components"
// import { regex, toasts } from "common/constants"
// import { useLogin } from "api/use-login"
// import { useEffect } from "react"
// import { toast } from "../toast"

const Logout = (_: WithRouter) => {
  // const login = useLogin()

  // const { register, handleSubmit, errors } = useForm({
  //   mode: "onTouched"
  // })

  // const onSubmit = () => {
  //   logout.mutate(session)
  // }

  // // const disabled =
  // //   !formState.isValid || formState.isSubmitting || login.isLoading

  // const t = {
  //   common: useTranslation("common"),
  //   view: useTranslation("views.login"),
  //   validate: useTranslation("validate"),
  //   errors: useTranslation("messages.errors")
  // }

  // // TODO: Make this a custom hook, maybe even combine to useVideo or consider yet another generic wrapper...
  // useEffect(() => {
  //   if (login.isError) {
  //     toast(t.errors("network"), {
  //       id: toasts.network
  //     })
  //   }
  // }, [login.isError])

  // if (login.isSuccess) {
  //   return (
  //     <>
  //       <Heading>{t.view("successHeading")}</Heading>
  //       <Text>{t.view("successBody")}</Text>
  //     </>
  //   )
  // }

  return (
    <>
      <Heading>Work in progress</Heading>
      <Text>Check back later</Text>
    </>
  )
}

export { Logout }
