import {
  NavBar,
  QueryMessager,
  PageContextProvider,
  LoggedInProvider
} from "components"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
// import { persistWithLocalStorage } from "react-query/persist-localstorage-experimental"
// import { Helmet } from "react-helmet"
import { TranslationProvider, en } from "translations"
import { Toaster } from "components/toast"
import { Box, InitializeColorMode, useThemeUI } from "theme-ui"
// import { useStaticQuery, graphql } from "gatsby"
import { Transition, BodyWrapper, Globals } from "./components"
import { Props } from "./types"
import { queryClient } from "./query-client"
// import { useBreadcrumb } from "gatsby-plugin-breadcrumb"

/**
 * TODO:
 * - find out why body needs to be set to absolute, to make bounding rect stay on top
 * - can we just get the location here?
 */

const Default = ({ children, location, pageContext, ...rest }: Props) => {
  const {
    theme: { sizes }
  } = useThemeUI() as any

  return (
    <>
      <Globals />
      <InitializeColorMode />
      <PageContextProvider pageContext={pageContext}>
        <QueryClientProvider client={queryClient}>
          <TranslationProvider translations={en}>
            <LoggedInProvider>
              {/* <Helmet>
            
          </Helmet> */}
              <NavBar />
              <Transition location={location}>
                <BodyWrapper>
                  <Box
                    px={4}
                    py={3}
                    mt={`${sizes.navBar}px`}
                    sx={{
                      position: `relative`,
                      maxWidth: `${sizes.xl}px`,
                      width: `100%`
                    }}
                  >
                    {children}
                  </Box>
                </BodyWrapper>
              </Transition>
              <Toaster />
              <QueryMessager />
            </LoggedInProvider>
          </TranslationProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PageContextProvider>
    </>
  )
}
export { Default }
