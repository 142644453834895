export const dissociate = (key: string) => <T>(obj: {
  [key: string]: T
}): { [key: string]: T } => {
  const result = Object.assign(obj)
  delete result[key]
  return result
}

export const ensureArray = <T>(value: T | Array<T>): Array<T> =>
  Array.isArray(value) ? value : [value]
