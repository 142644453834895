import styled from "@emotion/styled"
import { useThemeUI } from "theme-ui"

/**
 * TODO:
 * - implement styled theme, especially for gap
 */

interface Props {
  direction?: "horizontal" | "vertical"
  gap?: number
}

const Stack = styled.div(({ direction = "vertical", gap }: Props) => {
  const { theme } = useThemeUI() as any
  return {
    display: "flex",
    flexDirection: direction === "vertical" ? "column" : "row",
    ...(gap && {
      "> :not(:first-of-type)": {
        marginTop: theme.space[gap]
      }
    })
  }
})

export { Stack }
