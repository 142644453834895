import { events, toasts as toastConstants } from "common/constants"
import { emitter } from "common/emitter"
import { dissociate } from "common/helpers"
import { useCallback, useEffect, useState } from "react"
import { ToastOptions, Toasts } from "./types"

const removeTimers: ReturnType<typeof setTimeout>[] = []

export const useToasts = () => {
  const [toasts, setToasts] = useState<Toasts>({})

  const toast = useCallback(
    ([message, { id, persist, variant }]: [string, ToastOptions]) => {
      setToasts((currentToasts: Toasts) => ({
        ...currentToasts,
        [id!]: {
          message,
          persist,
          variant
        }
      }))
      if (!persist) {
        removeTimers.push(
          setTimeout(() => {
            removeToast(id!)
          }, toastConstants.timeout)
        )
      }
    },
    []
  )

  const removeToast = useCallback((id: string) => {
    setToasts(
      id
        ? (currentToasts: Toasts) => ({ ...dissociate(id)(currentToasts) })
        : {}
    )
  }, [])

  useEffect(() => {
    emitter.on(events.addToast, toast)

    emitter.on(events.removeToast, removeToast)

    return () => {
      emitter.off(events.addToast, toast)
      emitter.off(events.removeToast, removeToast)
      // removeTimers.forEach((timer) => clearTimeout(timer))
    }
  }, [])

  return { toasts }
}
