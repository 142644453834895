import { toasts } from "common/constants"
import { toast } from "components/toast"
import { useEffect } from "react"
import { useTranslation } from "translations"

/**
 * TODO:
 * - This is somehow not right, I wish to be able to return from this function
 */

export const useErrorToast = (error: any) => {
  const t = useTranslation("messages.errors")
  useEffect(() => {
    if (!error) {
      return
    }
    const message =
      process.env.NODE_ENV === "development"
        ? [t("network"), JSON.stringify(error.message)].join(" /// ")
        : t("network")

    error &&
      toast(message, {
        id: toasts.network
      })
  }, [error])
}
