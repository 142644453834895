import { Text } from "theme-ui"
import { useCard } from ".."

const Description = ({ children }: WithOptionalChildren) => {
  const { description } = useCard()
  return (
    <Text
      as="div"
      px={4}
      sx={{
        marginTop: 3,
        marginBottom: 4
      }}
    >
      {children ?? description}
    </Text>
  )
}

export { Description }
