import { Flex, Box, BoxProps, FlexProps } from "theme-ui"
import { NavLink } from "components"
import { MotionProps, AnimatePresence, motion } from "framer-motion"
import { useTranslation } from "translations"
import { useLocation } from "@reach/router"

type BreadCrumb = {
  label: string
  path: string
}

interface Props extends FlexProps {
  crumbs: BreadCrumb[]
}

const motionProps: MotionProps = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1,
    transition: {
      opacity: { type: "tween", duration: 0.3, delay: 0.3 }
    }
  },
  exit: {
    opacity: 0,
    transition: {
      opacity: { type: "tween", duration: 0.2, delay: 0.2 }
    }
  }
}

const hasCrumb = ({ label, path }: any) => !!label && !!path

const Crumb = ({ children }: BoxProps) => (
  <Box
    color="heading"
    sx={{
      whiteSpace: `nowrap`,
      "&:after": {
        content: `"\u003E"`,
        opacity: 0.5
      }
    }}
  >
    {children}
  </Box>
)

const Breadcrumbs = ({ crumbs: pathCrumbs = [], ...rest }: Props) => {
  const t = useTranslation(`routes`)
  const crumbs = [{ label: t(`home`), path: `/` }, ...pathCrumbs]
  const location = useLocation()
  const isRoot = location.pathname === "/"

  return (
    <Flex as="nav" {...rest}>
      <AnimatePresence>
        {!isRoot &&
          crumbs.filter(hasCrumb).map(({ label, path }) => (
            <motion.div {...motionProps} key={path}>
              <Crumb>
                <NavLink to={path} p={2}>
                  {label}
                </NavLink>
              </Crumb>
            </motion.div>
          ))}
      </AnimatePresence>
    </Flex>
  )
}

export { Breadcrumbs }
