import { usePage } from "api/use-page"
import { Spinner } from "components"
import MDX from "@mdx-js/runtime"
import styled from "@emotion/styled"

/**
 * TODO:
 * - Make sure this is safe (it should be as there shouldn't be any user input rendered in this fashion)
 * - remove dev notifications in production
 * - optimize cache for production
 * - Navigate users to login if they are not logged in?
 */

interface Props {
  page: string
}

const Wrap = styled.div({
  background: "rgba(255, 255, 255, 0.15)",
  padding: "2em"
})

const Note = styled.div({
  borderRadius: "0.5em",
  background: "rgba(0, 0, 0, 0.5)",
  fontSize: "0.75em",
  position: "absolute",
  padding: "0.5em"
})

const Dynamic = ({ page }: Props) => {
  const { isLoading, data, error } = usePage(page, {
    refetchOnWindowFocus: true
  })

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    return null
  }

  return (
    <Wrap>
      <Note>Dynamic</Note>
      <MDX>{data}</MDX>
    </Wrap>
  )
}

export { Dynamic }
