import { Rect } from "components"
import { useCard } from ".."
import Img from "gatsby-image"
import { Box, BoxProps } from "theme-ui"

const Body = ({ sx, children, ...rest }: WithChildren<BoxProps>) => {
  const { interactive, image } = useCard()

  return (
    <Rect
      interactive={interactive}
      sx={{
        flexDirection: `column`,
        alignItems: `flex-start`,
        height: `100%`,
        ...sx
      }}
      {...rest}
    >
      {children}
      {image && (
        <Box
          sx={{
            position: `absolute`,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            maskImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.35) 1em, rgba(0, 0, 0, 1) 3em)`
          }}
        >
          <Img
            style={{
              position: `absolute`,
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              filter: `grayscale(1) blur(.75px) opacity(0.125) brightness(1.25)`
            }}
            fluid={image}
            alt=""
          />
        </Box>
      )}
    </Rect>
  )
}

export { Body }
