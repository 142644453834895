import { alpha } from "@theme-ui/color"
import { button } from "./common"

/**
 * TODO:
 * - fix input styles
 * - use theme-ui tokens where possible instead of directly referencing the object
 */

const colors = {
  primary: `#f5be4b`, // Buttons, etc.
  secondary: `#f9d995`, // Hover states
  muted: `#333`, // Decorative elements over background
  accent: `#00fff6`, // Elements that really need to stand out
  text: `#d5d7d8`,
  heading: `#f5be4b`,
  inverted: `#1b1c1e`, // Text on brighter backgrounds
  bright: `#c3c3c3`, // Generic bright background for notifications, dialogs etc.
  background: `#1b1c1e`,
  error: `#ac1616`,
  ok: `#41ac16`,
  ripple: `#d4ffd3`,
  modes: {
    light: {
      primary: `#d6ba80`, // Buttons, etc.
      secondary: `#ffe3a8`, // Hover states
      muted: `#333`, // Decorative elements over background
      accent: `#00fff6`, // Elements that really need to stand out
      text: `#706143`,
      heading: `#85744f`,
      inverted: `#584d35`, // Text on brighter backgrounds
      bright: `#c3c3c3`, // Generic bright background for notifications, dialogs etc.
      background: `#fff`,
      error: `#ac1616`,
      ok: `#41ac16`,
      ripple: `#b1ab7f`
    }
  }
}

const space = [0, 0.125, 0.25, 0.5, 1, 1.5, 3].map((n) => `${n}rem`)

const sizes = {
  // container widths
  xs: 360,
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
  navBar: 92,
  breadCrumbs: 32,
  card: 128
}

const fonts = {
  body: `lato, sans-serif`,
  heading: `"Chakra Petch", sans-serif`,
  monospace: `monospace`
}

const fontSizes = [11, 14, 16, 20, 24, 32, 48, 64]

const fontWeights = {
  body: 400,
  heading: 600,
  bold: 700
}

const lineHeights = {
  body: 1.5,
  heading: 1.125
}

const letterSpacings = {
  body: `normal`,
  caps: `0.2em`
}

const styles = {
  root: {
    fontFamily: `body`,
    fontWeight: `body`,
    "*": {
      "&:focus": {
        outline: `none`
      },
      "&:focus-visible": {
        boxShadow: `0 0 0 3px rgba(40, 185, 195, 0.75)`
      }
    }
  },
  h1: {
    fontSize: 6,
    fontFamily: `heading`,
    lineHeight: `heading`,
    fontWeight: `heading`,
    color: `heading`,
    mt: 4,
    mb: 2
  },
  h2: {
    fontSize: 5,
    fontFamily: `heading`,
    lineHeight: `heading`,
    fontWeight: `heading`,
    color: `heading`,
    mt: 4,
    mb: 2
  },
  h3: {
    fontSize: 4,
    fontFamily: `heading`,
    lineHeight: `heading`,
    fontWeight: `heading`,
    color: `heading`,
    mt: 4,
    mb: 2
  },
  h4: {
    fontSize: 3,
    fontFamily: `heading`,
    lineHeight: `heading`,
    fontWeight: `heading`,
    color: `heading`,
    mt: 4,
    mb: 2
  },
  h5: {
    fontSize: 2,
    fontFamily: `heading`,
    lineHeight: `heading`,
    fontWeight: `heading`,
    color: `heading`,
    mt: 4,
    mb: 2
  },
  h6: {
    fontSize: 2,
    fontFamily: `heading`,
    lineHeight: `heading`,
    fontWeight: `body`,
    color: `heading`,
    mt: 4,
    mb: 2
  },
  p: {
    fontSize: 2,
    fontFamily: `body`,
    lineHeight: `body`,
    fontWeight: `body`,
    color: `text`,
    mt: 4,
    mb: 2
  }
}

const radii = {
  none: `0`,
  sm: `0.125rem`,
  default: `0.25rem`,
  md: `0.375rem`,
  lg: `0.5rem`,
  full: `9999px`
}

const shadows = {
  default: `0 3px 6px rgb(0 0 0 / 0.35)`,
  modes: {
    light: {
      default: `0px 3px 3px rgb(0 0 0 / 0.1)`
    }
  }
}

const links = {
  nav: {
    px: 2,
    py: 1,
    textTransform: `uppercase`,
    fontSize: 1,
    color: `heading`,
    whiteSpace: `nowrap`,
    borderRadius: radii.default,
    "&:hover": {
      color: `heading`,
      backgroundColor: alpha(`heading`, 0.1)
    }
  }
}

const fields = {
  default: {
    borderRadius: radii.default,
    background: `#f1f1f1`,
    padding: `0.5em`,
    color: `#1b1c1e`,
    fontSize: `1.25em`,
    backgroundImage: `linear-gradient(0deg, #f5be4b29, #ffffff) !important`,
    border: `1px solid rgba(0, 0, 0, 0.5)`,
    "&:not(:focus-visible)": {
      boxShadow: `-2px -1px 3px rgba(0, 0, 0, 0.5), 2px 1px 3px rgba(255,255, 255, 0.05)`
    }
  }
}

const buttons = {
  primary: {
    ...button
  },
  plain: {
    boxShadow: `none`,
    background: `none`,
    margin: 0,
    padding: 0
  },
  disabled: {
    pointerEvents: `none`,
    filter: `grayscale(90%)`,
    opacity: 0.8
  }
}

const breakpoints = [`40em`, `56em`, `64em`]

// toast: `linear-gradient(45deg, rgba(218, 209, 0, 0.9), rgba(71, 195, 161, 0.75))`
const gradients = {
  toast: `linear-gradient(45deg, #f5be4be6, #f9d995bf)`,
  modes: {
    light: {
      toast: `linear-gradient(45deg, #d6ba80e6, #ffe3a8bf)`
    }
  }
}

export default {
  colors,
  space,
  sizes,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  styles,
  links,
  radii,
  buttons,
  breakpoints,
  fields,
  shadows,
  gradients
}
