import { Heading } from "theme-ui"
import { useCard } from ".."

const Title = ({ children }: WithOptionalChildren) => {
  const { title } = useCard()
  return (
    <>
      <Heading px={4} sx={{ paddingBottom: 3, marginTop: 3 }}>
        {children ?? title}
      </Heading>
    </>
  )
}

export { Title }
