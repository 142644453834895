import { Rect, Button } from "components"
import { Text, Flex, useThemeUI } from "theme-ui"
import { removeToast } from "./emitter"
import { Variant } from "./types"

/**
 * TODO:
 * - implement variants
 */

type Props = {
  message?: string
  id?: string
  persist?: boolean
  variant?: Variant
}

export const Toast = ({
  id,
  message,
  // variant = "Success",
  persist = false
}: Props) => {
  const { theme, colorMode } = useThemeUI() as any
  const gradient =
    colorMode === `light`
      ? theme.gradients.modes.light.toast
      : theme.gradients.toast

  return (
    <Rect
      bg="none"
      px={4}
      py={3}
      sx={{
        backgroundImage: gradient,
        maxWidth: "xs"
      }}
    >
      <Flex sx={{ justifyContent: `space-between`, alignItems: `center` }}>
        {message && <Text>{message}</Text>}
        {persist && (
          <Button
            sx={{ paddingLeft: 2, paddingRight: 2 }}
            variant="buttons.plain"
            onClick={() => removeToast(id!)}
          >
            ✖
          </Button>
        )}
      </Flex>
    </Rect>
  )
}
