import { Link } from "gatsby"
import { routes } from "common/constants"
import { useTranslation } from "translations"
import {
  Wide,
  ScreenReader,
  NavLink,
  Breadcrumbs,
  usePageContext
} from "components"
import { Flex, Box, Switch, useColorMode } from "theme-ui"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { useState } from "react"
import { useLoggedIn } from "api/use-logged-in"

/**
 * TODO:
 * - Clean up file, multiple subcomponents
 * - implement mobile navigation
 * - implement proper color mode control
 */

const NavBar = () => {
  const [top, setTop] = useState(true)
  const [colorMode, setColorMode] = useColorMode<`default` | `light`>()
  const { crumbs } = usePageContext()
  // const { loggedIn } = useLoggedIn()

  // console.log("Logged in", loggedIn)

  useScrollPosition(({ currPos }) => setTop(currPos.y === 0), [top])

  const t = {
    common: useTranslation("common"),
    routes: useTranslation("routes")
  }

  return (
    <Flex
      sx={{
        flexDirection: `column`,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        height: "navBar",
        backgroundColor: "background",
        zIndex: 9000,
        "&::before": {
          content: `""`,
          height: `2em`,
          display: "block",
          backgroundImage:
            "radial-gradient(at center center, rgba(0, 0, 0, 0.65) 0%, transparent 70%, transparent 100%)",
          left: 0,
          right: 0,
          bottom: 0,
          position: "absolute",
          transform: "translate3d(0px, 1em, -1px)",
          zIndex: "-1",
          pointerEvents: "none",
          opacity: top ? 0 : 1,
          transition: "opacity .2s linear"
        }
      }}
    >
      <Flex
        px={3}
        sx={{
          backgroundColor: `background`,
          justifyContent: "space-between",
          left: 0,
          top: 0,
          right: 0
        }}
      >
        <Flex my={3} sx={{ alignItems: "center" }}>
          <Link to="/">
            <ScreenReader>{t.common("title")}</ScreenReader>
            <Wide sx={{ height: "3rem" }} />
          </Link>
        </Flex>
        <Flex
          sx={{
            flexDirection: `column`,
            alignItems: `center`,
            justifyContent: `center`
          }}
        >
          <Switch
            sx={{}}
            value={colorMode}
            onChange={() =>
              setColorMode(colorMode === `light` ? `default` : `light`)
            }
          />
        </Flex>
        <Box
          as="nav"
          sx={{
            alignItems: "center",
            display: ["none", "flex"]
          }}
        >
          {/* <NavLink to={routes.blog} p={2}>
          {t.routes("blog")}
        </NavLink>
        <NavLink to={routes.courses} p={2}>
          {t.routes("courses")}
        </NavLink>
        <NavLink to={routes.login} p={2}>
          {t.routes("login")}
        </NavLink>
        <NavLink to={routes.signup} p={2}>
          {t.routes("signup")}
        </NavLink> */}
          <NavLink to="#" p={2}>
            {t.routes("blog")}
          </NavLink>
          <NavLink to="#" p={2}>
            {t.routes("courses")}
          </NavLink>
          <NavLink to="#" p={2}>
            {t.routes("login")}
          </NavLink>
          <NavLink to="#" p={2}>
            {t.routes("signup")}
          </NavLink>
        </Box>
      </Flex>
      <Breadcrumbs
        crumbs={crumbs}
        sx={{
          paddingLeft: 3,
          height: "breadCrumbs",
          alignItems: `center`,
          backgroundColor: "background"
        }}
      />
    </Flex>
  )
}

export { NavBar }
