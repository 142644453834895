import { useVideo } from "api/use-video"
import { toasts } from "common/constants"
import { Spinner, AspectRatio } from "components"
import {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
  LegacyRef
} from "react"
import ReactPlayer from "react-player"
import { useTranslation } from "translations"
import { toast } from "../toast"
import { motion, useAnimation } from "framer-motion"
import { Seek, Chapters, Controls } from "./components"

// https://github.com/CookPete/react-player

/**
 * TODO:
 * - implement customized controls:
 *   - speed
 *   - volume
 *
 * - add loaded video indication
 * - replace slider with custom functionality for chapter view
 * - fix position of duration control
 *
 * - figure out whether to make this element generic video player or the "main course video player" with some extra features. I will need a video player for blog posts and so on as well.
 */

interface Props {
  id: string
  chapters?: any
}

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
}

interface ContextProps {
  position: number
  seekStart: () => void
  seekUpdate: (event: any) => void
  seekStop: (event: any) => void
  duration: number
  playing: boolean
  play: () => void
  pause: () => void
  activeChapter?: string
  player?: ReactPlayer
  chapters?: VTTCue[]
}

const Context = createContext<ContextProps | null>(null)

const PrivateVideo = ({ id, chapters: chapterVTT }: Props) => {
  const { isLoading, data, error } = useVideo(id)
  const player = useRef<ReactPlayer>()
  const [ready, setReady] = useState(false)
  const [seeking, setSeeking] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [position, setPosition] = useState(0)
  const [duration, setDuration] = useState(0)
  const [activeChapter, setActiveChapter] = useState<string>()

  const t = useTranslation("messages.errors")
  const controls = useAnimation()
  const chapterTrack = useRef<TextTrack>()
  const [chapters, setChapters] = useState<VTTCue[]>()

  const pause = () => {
    setPlaying(false)
  }

  const play = () => {
    setPlaying(true)
  }

  const seekStart = () => {
    setSeeking(true)
  }

  const seekUpdate = (event: any) => {
    setPosition((duration / 100) * parseFloat(event.target.value))
  }

  const seekStop = (event: any) => {
    setSeeking(false)
    player.current!.seekTo(position)
  }

  const updatePosition = () => {
    setPosition(player.current!.getCurrentTime())
  }

  // const handlePlayPause = () => setPlaying(!playing)

  // TODO: Make this a custom hook, maybe even combine to useVideo or consider yet another generic wrapper...
  useEffect(() => {
    if (error) {
      toast(t("network"), {
        id: toasts.network
      })
    }
  }, [error])

  useEffect(() => {
    if (ready) {
      controls.start("visible") // Show player
      setDuration(player.current!.getDuration())
      const chapterTrack = Array.from(
        (player.current!.getInternalPlayer() as HTMLVideoElement).textTracks
      ).find((track) => track.kind === `chapters`)

      if (
        !chapterTrack ||
        !chapterTrack.cues ||
        chapterTrack.cues.length === 0
      ) {
        return
      }

      // Set chapter title update
      chapterTrack.oncuechange = function (this: TextTrack, ev: Event) {
        this?.activeCues &&
          this.activeCues.length > 0 &&
          setActiveChapter((this.activeCues[0] as VTTCue).text) // TODO: Maybe clean this up
      }

      // Set chapter list
      setChapters(Array.from(chapterTrack.cues).map((cue) => cue as VTTCue))
    }
  }, [ready])

  // TODO: Fix config for other stuff if needed
  const config = chapterVTT && {
    file: {
      tracks: [
        {
          kind: `chapters`,
          src: chapterVTT,
          srcLang: `en`,
          label: `Locations`,
          default: true
        }
      ]
    }
  }

  const context = {
    position,
    seekStart,
    seekUpdate,
    seekStop,
    player: player.current,
    activeChapter,
    chapters,
    duration,
    playing,
    play,
    pause
  }

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    return <div>Cannot display video: {JSON.stringify(error)}</div>
  }

  return (
    <Context.Provider value={context}>
      <AspectRatio>
        <motion.div variants={variants} initial="hidden" animate={controls}>
          <ReactPlayer
            ref={player as LegacyRef<ReactPlayer>}
            url={data.url}
            controls={false}
            width="100%"
            height="100%"
            onReady={() => setReady(true)}
            config={config}
            playing={playing}
            onProgress={updatePosition}
          />
        </motion.div>
      </AspectRatio>
      <Seek />
      <Chapters />
      <Controls />
    </Context.Provider>
  )
}

const usePrivateVideo = () => {
  const _context = useContext(Context)
  if (!_context) {
    throw new Error(`Page context can not be used outside PageContextProvider`)
  }
  return _context
}

export { PrivateVideo, usePrivateVideo }
