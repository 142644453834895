import { QueryClient } from "react-query"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
})
// persistWithLocalStorage(queryClient) // TODO: Clear this in logout / login

export { queryClient }
