import { lighten } from "@theme-ui/color"

const button = {
  color: `inverted`,
  fontWeight: 400,
  boxShadow: `default`,
  borderRadius: `default`,
  transition: `all 0.1s ease-in`,
  overflow: `hidden`,
  cursor: `pointer`,
  fontSize: `1.25em`,
  "&:hover": {
    backgroundColor: lighten(`primary`, 0.1)
  }
}

export { button }
