import { motion, AnimatePresence } from "framer-motion"
import { Props } from "layouts/types"

const Transition = ({ children, location }: Props) => (
  <AnimatePresence>
    <motion.div
      key={location.pathname}
      initial={{
        position: "absolute",
        y: 10,
        opacity: 0
      }}
      animate={{
        y: 0,
        opacity: 1,
        transition: {
          type: "tween",
          ease: "easeOut",
          duration: 0.2,
          delay: 0.2
        }
      }}
      exit={{
        opacity: 0,
        transition: {
          type: "tween",
          ease: "easeOut",
          duration: 0.2
        }
      }}
    >
      {children}
    </motion.div>
  </AnimatePresence>
)

export { Transition }
