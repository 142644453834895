// import { navigate } from "gatsby"
import { ComponentType } from "react"
import { useLoggedIn } from "../api/use-logged-in"

/**
 * TODO:
 * - what is this really?
 */

interface Props {
  path: string
  component: ComponentType | string
}

const MemberRoute = ({ component: Component, path, ...rest }: Props) => {
  const { isLoading, data, error, isFetching } = useLoggedIn()

  if (isLoading) {
    // TODO: implement
    return <>"Loading"</>
  }

  console.log("data", data)

  // if (!data && path !== `/app/login`) {
  //   navigate("/app/login")
  //   return null
  // }

  return <Component {...rest} />
}

export { MemberRoute }
