import { useRef, useEffect, useCallback, useState } from "react"
import { dissociate } from "common/helpers"
import { ripples } from "common/constants"
import { keyframes } from "@emotion/react"
import { Box } from "theme-ui"
import { alpha as UiAlpha } from "@theme-ui/color"
import styled from "@emotion/styled"
type RippleType = {
  clickX: number
  clickY: number
  dismounting: boolean
  width: number
  height: number
  container: HTMLElement
  color: string
  alpha: number
  fadeOut: (id: string) => void
  remove: (id: string) => void
}

type Options = {
  color?: string
  alpha?: number
}

const RippleContent = styled.div({
  position: "relative",
  zIndex: 1
})

const Ripples = styled.div({
  zIndex: -1
})

const useRipples = (options = {} as Options) => {
  const { color = "accent", alpha = 0.15 } = options
  const [ripples, setRipples] = useState<Record<string, RippleType>>({})

  const fadeOutRipple = useCallback((id: string) => {
    setRipples((currentRipples) => ({
      ...currentRipples,
      [id]: {
        ...currentRipples[id],
        dismounting: true
      }
    }))
  }, [])

  const removeRipple = useCallback((id: string) => {
    setRipples((currentRipples) => ({ ...dissociate(id)(currentRipples) }))
  }, [])

  const addRipple = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const {
        x,
        y,
        width,
        height
      } = event.currentTarget.getBoundingClientRect()
      const clickX = event.clientX - x
      const clickY = event.clientY - y
      const id = String(Date.now())
      const container = event.currentTarget

      const fadeOut = () => fadeOutRipple(id)
      const remove = () => removeRipple(id)
      setRipples((currentRipples) => ({
        ...currentRipples,
        [id]: {
          clickX,
          clickY,
          dismounting: false,
          width,
          height,
          fadeOut,
          remove,
          container,
          color,
          alpha
        }
      }))
    },
    []
  )

  const RippleComponents = (
    <Ripples>
      {Object.entries(ripples).map(([id, ripple]) => (
        <Ripple key={id} id={id} {...ripple} />
      ))}
    </Ripples>
  )

  return [addRipple, RippleComponents] as const
}

// TODO: Get rid of the need to use RippleContent
export { useRipples, RippleContent }

type Props = RippleType & {
  id: string
}

const focus = keyframes({
  "0%": {},
  "100%": {
    transform: "scale(2.25)"
  }
})

export const Ripple = ({
  clickX,
  clickY,
  dismounting,
  id,
  width,
  height,
  fadeOut,
  remove,
  container,
  color,
  alpha
}: Props) => {
  const removeTimeout = useRef<ReturnType<typeof setTimeout>>()

  const handleRemove = useCallback(() => {
    fadeOut(id)
    removeTimeout.current = setTimeout(
      () => remove(id),
      ripples.duration + ripples.minimumVisibleDuration
    )
  }, [])

  useEffect(() => {
    addEventListener("mouseup", handleRemove)
    container.addEventListener("mouseleave", handleRemove)
    return () => {
      removeTimeout.current && clearTimeout(removeTimeout.current)
      removeEventListener("mouseup", handleRemove)
      container.removeEventListener("mouseleave", handleRemove)
    }
  }, [])

  const position =
    width >= height
      ? {
          width,
          height: 0,
          paddingBottom: "100%",
          left: `calc(-50% + ${clickX}px)`,
          top: -width / 2 + clickY
        }
      : {
          width: 0,
          height,
          paddingLeft: height,
          top: `calc(-50% + ${clickY}px)`,
          left: -height / 2 + clickX
        }

  return (
    <Box
      sx={{
        position: "absolute",
        overflow: "hidden",
        display: "block",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        "&::after": {
          content: `""`,
          background: UiAlpha(color, alpha),
          boxShadow: UiAlpha(color, Math.min(alpha + 0.1, 1)),
          animation: `${focus} ${ripples.duration}ms ease-out forwards`,
          position: "absolute",
          borderRadius: "9999px",
          transform: "scale(0.01)",
          ...(dismounting && { opacity: 0 }),
          transition: `opacity ${ripples.duration}ms ease-out ${ripples.minimumVisibleDuration}ms`,
          ...position
        }
      }}
    />
  )
}
