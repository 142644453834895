import { emitter } from "common/emitter"
import { events } from "common/constants"
import { ToastOptions } from "./types"

const toast = (message: string, options?: ToastOptions) =>
  emitter.emit(events.addToast, message, {
    id: options?.id ?? String(Date.now()),
    persist: options?.persist ?? true,
    variant: options?.variant ?? "Default"
  })

const removeToast = (id: string) => emitter.emit(events.removeToast, id)

export { toast, removeToast }
