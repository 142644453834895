import { usePrivateVideo } from "../private-video"
import { Slider, Box } from "theme-ui"

/**
 * TODO:
 * - fix seek position in relation to duration
 */

const Seek = () => {
  const {
    duration,
    position,
    seekStart,
    seekUpdate,
    seekStop
  } = usePrivateVideo()
  console.log("p", position)
  console.log("d", duration)

  const value =
    position === 0 && duration === 0 ? 0 : (position / duration) * 100

  console.log(value)

  return (
    <Box py={3}>
      <Slider
        value={value}
        onMouseDown={seekStart}
        onChange={seekUpdate}
        onMouseUp={seekStop}
      />
    </Box>
  )
}

export { Seek }
