import EventEmitter from "eventemitter3"

const eventEmitter = new EventEmitter()

const emitter = Object.freeze({
  emit: (event: string | symbol, ...args: any[]) =>
    eventEmitter.emit(event, args),
  on: (event: string | symbol, action: EventEmitter.ListenerFn<any[]>) =>
    eventEmitter.on(event, action),
  off: (event: string | symbol, action: EventEmitter.ListenerFn<any[]>) =>
    eventEmitter.off(event, action),
  get: eventEmitter
})

export { emitter }
