import { useMutation } from "react-query"
import axios from "axios"
import { api } from "../common/constants"

const useLogin = () =>
  useMutation((email: string) =>
    axios.post(api.login, JSON.stringify({ email }), {
      withCredentials: true
    })
  )

export { useLogin }
