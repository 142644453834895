import { useEffect, useRef } from "react"
import { useTranslation } from "translations"
import { toast } from "./toast"
/**
 * Handles showing notifications on redirect. This is mainly to handle showing notifications on login/account verification/sales redirects.
 */

const QueryMessager = () => {
  const t = useTranslation("messages.query", { errors: false })

  useEffect(() => {
    const messageQuery = new URLSearchParams(document.location.search).get("m")
    const message = messageQuery && t(messageQuery)
    if (!message) {
      return
    }

    console.log("message", t(messageQuery!))
    toast(message)
    window.history.replaceState({}, document.title, document.location.pathname)
  }, [])
  // useEffect(() => {
  //   ;[...Array(2)].forEach((_, i) =>
  //     add({
  //       message: `Test queries ${i}`,
  //       id: String(i)
  //     })
  //   )
  // }, [])
  return null
}

export { QueryMessager }
