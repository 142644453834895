import { Body, Description, Meta, Title } from "./components"
import { createContext, useContext } from "react"
import { FluidObject } from "gatsby-image"
import { BoxProps } from "theme-ui"

interface Context {
  title?: string
  description?: string
  author?: string
  tags?: string[]
  image?: FluidObject
  interactive?: boolean
}

const context = createContext<Context>({})

interface Props extends Context, BoxProps {}

const Card = ({
  children,
  title,
  description,
  author,
  tags,
  image,
  interactive,
  ...rest
}: WithChildren<Props>) => (
  <context.Provider
    value={{ title, description, author, tags, image, interactive }}
  >
    <Body {...rest}>{children}</Body>
  </context.Provider>
)

Card.Description = Description
Card.Meta = Meta
Card.Title = Title

const useCard = () => {
  const _context = useContext(context)
  if (!_context) {
    throw new Error(
      `Card compound components cannot be rendered outside the Card component`
    )
  }
  return _context
}

export { Card, useCard }
