import { alpha } from "@theme-ui/color"
import { Text, TextProps, useThemeUI } from "theme-ui"

const Tag = ({ children, as = `div` }: WithChildren<TextProps>) => {
  const { theme } = useThemeUI() as any
  return (
    <Text
      as={as}
      px={2}
      my={1}
      py={1}
      sx={{
        listStyle: `none`,
        fontWeight: 700,
        marginRight: 2,
        borderRadius: `default`,
        background: alpha(`background`, 0.1)(theme)
      }}
    >
      {children}
    </Text>
  )
}

export { Tag }
