import { Toast } from "./toast"
import { MotionProps, AnimatePresence, motion } from "framer-motion"
import { Portal } from "components"
import { useToasts } from "components/toast"
import { Grid } from "theme-ui"

const motionProps: MotionProps = {
  initial: {
    opacity: 0,
    x: "-100%",
    height: 0,
    overflow: "hidden",
    position: "relative"
  },
  animate: {
    opacity: 1,
    x: 0,
    height: "auto",
    transition: {
      height: { type: "spring", stiffness: 400, damping: 15, mass: 0.75 },
      x: { type: "tween", duration: 0.3, delay: 0.1 },
      opacity: { type: "tween", duration: 0.3, delay: 0.3 }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      height: { type: "tween", duration: 0.2, delay: 0.3 },
      opacity: { type: "tween", duration: 0.2, delay: 0.2 }
    }
  }
}

export const Toaster = () => {
  const { toasts } = useToasts()
  return (
    <Portal>
      <Grid
        columns={1}
        gap={3}
        sx={{
          position: "fixed",
          right: "1em",
          bottom: "1em",
          left: "1em"
        }}
      >
        <AnimatePresence>
          {Object.entries(toasts).map(([id, { message, persist, variant }]) => (
            <motion.div key={id} {...motionProps}>
              <Toast
                id={id}
                message={message}
                persist={persist}
                variant={variant}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </Grid>
    </Portal>
  )
}
