import { Button as UiButton, ButtonProps } from "theme-ui"
import { useRipples, RippleContent } from "common/use-ripples"

/**
 * TODO:
 * - should we use forwardRef?
 * - implement more variants
 */

const Button = ({
  children,
  sx,
  disabled,
  variant = `buttons.primary`,
  ...props
}: ButtonProps) => {
  const [addRipple, ripples] = useRipples({ color: `ripple`, alpha: 0.75 })

  return (
    <UiButton
      py={3}
      sx={{
        position: `relative`,
        variant: disabled ? `buttons.disabled` : variant,
        ...sx
      }}
      {...props}
      onMouseDown={addRipple}
    >
      <RippleContent>{children}</RippleContent>
      {ripples}
    </UiButton>
  )
}

export { Button }
