const en = {
  common: {
    logIn: `Login`,
    logOut: `Logout`,
    send: `Send`,
    profile: `Profile`,
    notLoggedIn: `You are not logged in`,
    notFound: `Content not available.`,
    dismiss: `Dismiss`,
    title: `Marozzo.com`,
    showNMore: `Show {amount} more`,
    hide: `Hide`
  },
  views: {
    profile: {
      title: `Profile`
    },
    login: {
      title: `Log In`,
      email: `Email address`,
      submit: `Send login link`,
      successHeading: `Just one more thing`,
      successBody: `Please check your email and click on the link we sent you to complete the login process.`
    },
    signup: {
      title: `Sign Up`,
      email: `Email address`,
      name: `Full name`,
      submit: `Sign Up`,
      successHeading: `You're almost done!`,
      successBody: `Please check your email and click on the link we sent you to verify your email address.`
    },
    home: {
      title: `Home`
    }
  },
  validate: {
    required: `Required`,
    email: `Must be a valid email address`
  },
  messages: {
    errors: {
      generic: `Error occurred.`,
      network: `Network error.`,
      notLoggedIn: `You need to log in to view this content.`
    },
    query: {
      welcome: `Welcome to Marozzo.com! 🖤`
    }
  },
  routes: {
    home: `Home`,
    blog: `Blog`,
    courses: `Courses`,
    login: `Log in`,
    signup: `Sign up`,
    about: `About`,
    privacyPolicy: `Privacy policy`,
    termsOfUse: `Terms of use`
  },
  components: {
    PrivateVideo: {
      play: "Play",
      pause: "Pause"
    }
  }
}
export { en }
