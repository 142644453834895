import { Heading, Text } from "theme-ui"
import { useTranslation } from "translations"
import { useForm } from "react-hook-form"
import { TextField, Button, Stack } from "components"
import { regex, toasts } from "common/constants"
import { useLogin } from "api/use-login"
import { useEffect } from "react"
import { toast } from "../toast"

interface Values {
  email: string
}

const Login = (_: WithRouter) => {
  const login = useLogin()

  const { register, handleSubmit, errors } = useForm({
    mode: "onTouched"
  })

  const onSubmit = ({ email }: Values) => {
    login.mutate(email)
  }

  // const disabled =
  //   !formState.isValid || formState.isSubmitting || login.isLoading

  const t = {
    common: useTranslation("common"),
    view: useTranslation("views.login"),
    validate: useTranslation("validate"),
    errors: useTranslation("messages.errors")
  }

  // TODO: Make this a custom hook, maybe even combine to useVideo or consider yet another generic wrapper...
  useEffect(() => {
    if (login.isError) {
      toast(t.errors("network"), {
        id: toasts.network
      })
    }
  }, [login.isError])

  if (login.isSuccess) {
    return (
      <>
        <Heading>{t.view("successHeading")}</Heading>
        <Text>{t.view("successBody")}</Text>
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="vertical">
        <TextField
          name="email"
          error={errors.email}
          ref={register({
            required: t.validate("required"),
            pattern: {
              value: regex.email,
              message: t.validate("email")
            }
          })}
          label={t.view("email")}
        />
        <Button my={3} sx={{ alignSelf: `flex-start` }} type="submit">
          {t.view("submit")}
        </Button>
      </Stack>
    </form>
  )
}

export { Login }
