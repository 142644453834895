import styled from "@emotion/styled"
import { ReactNode } from "react"

const defaultRatio = 16 / 9

type Props = {
  maxWidth?: string
  maxHeight?: string
  width?: string
  height?: string
  ratio?: number
  children: ReactNode
}

const AspectRatio = styled.div(
  ({ ratio = defaultRatio, children, ...props }: Props) => ({
    width: "100%",
    height: "100%",
    ...props,
    position: "relative",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&::before": {
      content: `""`,
      display: "block",
      paddingBottom: `${100 / ratio}%`
    },
    "> :first-of-type": {
      position: "absolute",
      top: 0,
      height: "100%"
    }
  })
)

export { AspectRatio }
