import { useMemo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Lesson } from "components"
import { useTranslation } from "translations"
import { Grid, Box, Flex } from "theme-ui"
import { useLocation } from "@reach/router"

// TODO: Replace with custom template
// TODO: move to helpers
const getOrder = ({ frontmatter }: any) => frontmatter?.order ?? 10e6
const normalizePath = (path: string) =>
  path === `/` ? path : path.replace(/\/$/, ``)
const parentPath = (path: string) => normalizePath(path).replace(/\/[^/]*$/, ``)
const pageTitle = ({ frontmatter, headings }: any) =>
  !frontmatter
    ? "N/A"
    : frontmatter.title || (headings.length && headings[0].value)

const Indicator = () => (
  <Box
    sx={{
      backgroundColor: `primary`,
      borderRadius: `full`,
      width: `0.25rem`,
      height: `0.25rem`,
      "&:not(:last-of-type)": {
        marginRight: 3
      }
    }}
  />
)

const Explorer = ({ children }: WithChildren) => {
  const data: any = useStaticQuery(graphql`
    query explorerQuery {
      allMdx {
        edges {
          node {
            id
            excerpt
            fields {
              slug
            }
            frontmatter {
              title
              order
            }
            headings(depth: h1) {
              value
            }
          }
        }
      }
    }
  `)
  const location = useLocation()
  const t = useTranslation(`common`)
  const [showAllPrevious, setShowAllPrevious] = useState(false)

  const pages = useMemo(
    () =>
      data.allMdx.edges
        .map(({ node }: any) => node)
        // .filter((page: any) => !page.frontmatter.hidden)
        .filter(
          (page: any) =>
            parentPath(page.fields.slug) === parentPath(location.pathname)
        )
        .map((page: any) => ({
          ...page,
          title: pageTitle(page),
          url: page.fields.slug,
          order: getOrder(page)
        }))
        .sort((a: any, b: any) => {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        })
        .sort((a: any, b: any) => a.order - b.order),
    [data]
  )

  const current: number = useMemo(
    () =>
      pages.findIndex(({ url }: { url: string }) => url === location.pathname),
    []
  )

  // const previous = useMemo(
  //   () =>
  //     current === 0
  //       ? []
  //       : showAllPrevious
  //       ? pages.slice(0, current)
  //       : pages.slice(Math.max(current - 2, 0), current),
  //   [showAllPrevious]
  // )

  const previous = useMemo(
    () => (current === 0 ? [] : pages.slice(0, current)),
    [showAllPrevious]
  )
  const next = useMemo(() => pages.slice(current + 1), [])

  return (
    <>
      {/* {current > 2 && !showAllPrevious && (
        <Button onClick={() => setShowAllPrevious(true)}>
            {t(`showNMore`, { amount: String(current - 2) })}
          </Button>
        )} */}
      {previous.length > 0 && !showAllPrevious && (
        <Flex
          role="button"
          onClick={() => setShowAllPrevious(true)}
          sx={{ justifyContent: `center` }}
        >
          {previous.map(({ title, url, id }: any) => (
            <Indicator />
          ))}
        </Flex>
      )}
      {previous.length > 0 && showAllPrevious && (
        <Grid columns={1} gap={3} sx={{ justifyItems: `center` }}>
          {previous.map(({ title, url, id }: any) => (
            <Lesson path={url} key={id}>
              {title}
            </Lesson>
          ))}
        </Grid>
      )}
      {children}
      <Grid columns={1} gap={3} sx={{ justifyItems: `center` }}>
        {next.map(({ title, url, id }: any) => (
          <Lesson path={url} key={id}>
            {title}
          </Lesson>
        ))}
      </Grid>
    </>
  )
}

export { Explorer }
