import { Card } from "components"
import { Link } from "gatsby"
import { Grid } from "theme-ui"

const PageListing = ({ pages }: any) => (
  <>
    <Grid as="ul" columns={[1, 2, 3, 4]} gap={4}>
      {pages?.map(({ node }: any) => (
        <Link to={node.fields.slug} key={node.id}>
          <Card
            interactive={true}
            title={node.frontmatter.title}
            description={node.excerpt}
            tags={node.frontmatter.tags}
            image={node.frontmatter.featuredImage?.childImageSharp.fluid}
            sx={{
              minHeight: `card`
            }}
          >
            <Card.Title />
            <Card.Description />
          </Card>
        </Link>
      ))}
    </Grid>
  </>
)

export { PageListing }
