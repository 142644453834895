import { ReactNode } from "react"
import { createPortal } from "react-dom"

interface Props {
  children: ReactNode
  container?: Element
  key?: string
}

export const Portal = ({ container, key, children }: Props) =>
  typeof document !== "undefined"
    ? createPortal(children, container ?? document.body, key)
    : null
