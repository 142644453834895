import { useMemo } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Card, usePageContext } from "components"
import { Grid } from "theme-ui"
import { useLocation } from "@reach/router"

const getOrder = ({ frontmatter }: any) => frontmatter?.order ?? 10e6
const normalizePath = (path: string) =>
  path === `/` ? path : path.replace(/\/$/, ``)
const parentPath = (path: string) => {
  console.log(normalizePath(path).replace(/\/[^/]*$/, ``))
  return normalizePath(path).replace(/\/[^/]*$/, ``)
}
const pageTitle = ({ frontmatter, headings }: any) =>
  !frontmatter
    ? "N/A"
    : frontmatter.title || (headings.length && headings[0].value)

const CourseNavigation = (props: any) => {
  const { dir } = usePageContext()
  const data: any = useStaticQuery(graphql`
    query navigationQuery {
      allMdx {
        edges {
          node {
            id
            excerpt
            fields {
              slug
            }
            frontmatter {
              title
              order
            }
            headings(depth: h1) {
              value
            }
          }
        }
      }
    }
  `)
  const location = useLocation()
  const pages = useMemo(
    () =>
      data.allMdx.edges
        .map(({ node }: any) => node)
        // .filter((page: any) => !page.frontmatter.hidden)
        .filter(
          (page: any) =>
            parentPath(page.fields.slug) === normalizePath(location.pathname)
        )
        .map((page: any) => ({
          ...page,
          title: pageTitle(page),
          url: page.fields.slug,
          order: getOrder(page)
        }))
        .sort((a: any, b: any) => {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        })
        .sort((a: any, b: any) => a.order - b.order),
    [data]
  )

  return (
    <>
      <Grid columns={[1, 2, 3, 4]} gap={4}>
        {pages?.map(({ title, url, frontmatter, excerpt, id }: any) => (
          <Link to={url} key={id}>
            <Card
              interactive={true}
              title={title}
              description={excerpt}
              tags={frontmatter.tags}
            >
              <Card.Meta />
              <Card.Title />
              <Card.Description />
            </Card>
          </Link>
        ))}
      </Grid>
    </>
  )
}

export { CourseNavigation }
