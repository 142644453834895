import { useMutation } from "react-query"
import axios from "axios"
import { api } from "../common/constants"

export type SignupData = {
  email: string
  name: string
}

const useSignup = () =>
  useMutation((data: SignupData) =>
    axios.post(api.signup, JSON.stringify(data), {
      withCredentials: true
    })
  )

export { useSignup }
