import { Box, Flex } from "theme-ui"
import { usePrivateVideo } from "../private-video"
import { Text } from "theme-ui"

const withinRange = (duration: number) => ({ startTime }: VTTCue) =>
  startTime < duration

const Chapters = () => {
  const { chapters, duration: totalDuration, activeChapter } = usePrivateVideo()

  if (!chapters) {
    return null
  }

  return (
    <Box>
      <Flex>
        {chapters
          ?.filter(withinRange(totalDuration))
          .map(({ startTime, endTime, text }) => {
            const duration = Math.min(endTime, totalDuration) - startTime
            const percentage = (duration / totalDuration) * 100
            return (
              <Flex
                bg="primary"
                p={3}
                key={startTime}
                sx={{
                  flexBasis: `${percentage}%`,
                  opacity: 0.5,
                  justifyContent: `center`,
                  alignItems: `center`,
                  "&:not(:last-of-type)": { marginRight: 2 },
                  "&:hover>div": {
                    opacity: 1
                  }
                }}
              >
                <Text
                  as="div"
                  color="primary"
                  p={3}
                  bg="background"
                  sx={{
                    transition: `opacity 200ms ease-out`,
                    opacity: 0,
                    position: `absolute`,
                    pointerEvents: `none`
                  }}
                >
                  {text}
                </Text>
              </Flex>
            )
          })}
      </Flex>
      {activeChapter && <Text>{activeChapter}</Text>}
    </Box>
  )
}

export { Chapters }
