import { Global } from "@emotion/react"
import LatoRegular from "assets/fonts/lato-v17-latin-regular.woff2"
import LatoRegularItalic from "assets/fonts/lato-v17-latin-italic.woff2"
import LatoBold from "assets/fonts/lato-v17-latin-700.woff2"
import LatoBoldItalic from "assets/fonts/lato-v17-latin-700italic.woff2"
import ChakraPetchRegular from "assets/fonts/chakra-petch-v4-latin-regular.woff2"
import ChakraPetch600 from "assets/fonts/chakra-petch-v4-latin-600.woff2"

const Globals = () => (
  <>
    <Global
      styles={{
        "*": {
          transition: `background-color 100ms ease-out, color 100ms ease-out, box-shadow 100ms ease-out, opacity: 200ms ease-out`
        },
        html: {
          overflowX: `hidden` // NOTE: Removes unwanted horizontal scrollbar -- might not be optimal
        },
        body: {
          position: "absolute",
          left: 0,
          top: 0,
          right: 0
        },
        a: {
          textDecoration: `none`
          // "&:hover": {
          //   h2: {
          //     textDecoration: `underline`
          //   }
          // }
        },
        ul: {
          padding: 0
        }
      }}
    />
    <Global
      styles={{
        "@font-face": {
          fontFamily: "lato",
          fontStyle: "normal",
          fontWeight: 400,
          src: `url(${LatoRegular}) format('woff2')`,
          fontDisplay: "block"
        }
      }}
    />
    <Global
      styles={{
        "@font-face": {
          fontFamily: "lato",
          fontStyle: "italic",
          fontWeight: 400,
          src: `url(${LatoRegularItalic}) format('woff2')`,
          fontDisplay: "block"
        }
      }}
    />
    <Global
      styles={{
        "@font-face": {
          fontFamily: "lato",
          fontStyle: "normal",
          fontWeight: 700,
          src: `url(${LatoBold}) format('woff2')`,
          fontDisplay: "block"
        }
      }}
    />
    <Global
      styles={{
        "@font-face": {
          fontFamily: "lato",
          fontStyle: "italic",
          fontWeight: 700,
          src: `url(${LatoBoldItalic}) format('woff2')`,
          fontDisplay: "block"
        }
      }}
    />
    <Global
      styles={{
        "@font-face": {
          fontFamily: "Chakra Petch",
          fontStyle: "normal",
          fontWeight: 400,
          src: `url(${ChakraPetchRegular}) format('woff2')`,
          fontDisplay: "block"
        }
      }}
    />
    <Global
      styles={{
        "@font-face": {
          fontFamily: "Chakra Petch",
          fontStyle: "normal",
          fontWeight: 600,
          src: `url(${ChakraPetch600}) format('woff2')`,
          fontDisplay: "block"
        }
      }}
    />
  </>
)

export { Globals }
