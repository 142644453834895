import { useQuery, UseQueryOptions } from "react-query"
import axios from "axios"
import { api, cache } from "../common/constants"
import { loggedIn } from "common/logged-in"

const useVideo = loggedIn((id: string, options?: UseQueryOptions) =>
  useQuery(
    ["video", id],
    async () => {
      const { data } = await axios.get(api.video(id), {
        withCredentials: true
      })
      return data
    },
    {
      cacheTime: cache.page,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  )
)

export { useVideo }
