import { createContext, useContext } from "react"

/**
 * TODO:
 * - create interface/typings for page context -- use the same also in gatsby-node.js if possible
 */

const context = createContext<any>(null)

const PageContextProvider = ({ children, pageContext }: any) => (
  <context.Provider value={pageContext}>{children}</context.Provider>
)

const usePageContext = () => {
  const _context = useContext(context)
  if (!_context) {
    throw new Error(`Page context can not be used outside PageContextProvider`)
  }
  return _context
}

export { PageContextProvider, usePageContext }
